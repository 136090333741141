import { getConfigEnv, ucfirst } from '@/utils/helpers';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_SETPOINT_TYPE,
} from '@/lib/constants';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { DateTime } from 'luxon';

const getDateAgoText = (tasq: TasqJob) => {
  let date = '';
  // @ts-ignore
  const waiting = tasq?.snoozed?.wait_until_reason && tasq?.snoozed?.until_date;
  let time = tasq?.assignmentTime;
  if (waiting) {
    // @ts-ignore
    time = tasq?.snoozed.until_date;
  }
  if (tasq?.predictionType === TASQ_OFF_TARGET_TYPE || tasq.predictionType === 'Off-Target RT') {
    time = tasq?.offTargetStartDate;
  }else if (tasq?.predictionType === TASQ_SETPOINT_TYPE) {
    time = new Date(tasq?.time);
  }

  if (tasq?.predictionType === TASQ_STATE_CHANGE_TYPE
    || tasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE
  ) {
    time = tasq?.stateChangeStart;
  }
  if (!time) {
    return date;
  }

  // @ts-ignore
  const timeVal = DateTime.fromISO(time);
  let value = DateTime.local().diff(timeVal, 'days').toObject()?.days?.toFixed();
  if (waiting) {
    value = timeVal.diff(DateTime.local(), 'days').days.toFixed();
  }

  if (value && value !== '0') {
    if(!Number.isNaN(Number(value)) && Number(value) > 365) {
      date = '> 365 days';
    } else {
      date = value === '1'
      ? `${value} day` : `${value} days`;
    }

    if (waiting) {
      date = `in ${date}`;
    }
  }
  if (value === '0' || value === '-0') {
    date = 'Today';
  }
  return {
    text: date,
    value: Math.abs(Number(value)),
  };
};

const getOverriddenTasqName = (tasq: TasqJobResponse) => {
  switch (tasq.PredictionType) {
    case TASQ_PROLONGED_ANOMALY_TYPE:
    case TASQ_STATE_CHANGE_TYPE:
      return 'Anomaly';
    case TASQ_OFF_TARGET_RT_TYPE:
      return 'Real-Time';
    case TASQ_WELL_TEST_TYPE:
	  return 'Well test';
    case 'Off-Target RT':
      return 'REAL-TIME';
    default:
      // @ts-ignore
      return tasq.ID ? 'Scheduled Job' : ucfirst(tasq.PredictionType || '');
  }
};




const getSnoozedForTodayStatus = (tasq: any) => {
  if (tasq.Snoozed && tasq.Snoozed.body && tasq.Snoozed.body.includes('Snoozed') && tasq.Snoozed.end_date && new Date(tasq.Snoozed.end_date).getTime() > new Date().getTime()) {
    return true;
  }

  return false;
};

const mapDefaultTasq = (t, userEmail = '', mapWaitingOn = true): TasqJob => {
  let wellName = t.wellName != null ? t.wellName : t.NodeID === 'Example Job' ? 'Braum Family 9-4-1XH' : t.NodeID;
  if (t.wellName != null) {
    wellName = t.wellName;
  } else {
    wellName = t.NodeID;
    if (t.NodeID.wellName != null) {
      wellName = t.NodeID.wellName;
    }
  }

  // if(mapWaitingOn){
  //   const payload = {wellName: wellName, padName: t.PadName}
  //   let wellStatus = (workflowModule.statusByWellName(wellName))
  //   let wellWaitingOnStatus = (gptModule.waitingOnStatusByWellName(wellName))
  //   t.Snoozed = wellWaitingOnStatus ? wellWaitingOnStatus : null;
  //   t.activeTodolistCount = wellStatus ? wellStatus.activeTodolistCount : null;
  //   t.activeTodolistForUser = wellStatus ? wellStatus.activeTodolistForUser : null;
  // }



  //snoozed end date is 3 days back from now then remove snooze
  if (t.Snoozed && t.Snoozed.UntilDate) {
    // @ts-ignore
    const endDate = DateTime.fromISO(t.Snoozed.end_date);
    let daysDiff: any = DateTime.local().diff(endDate, 'days').toObject()?.days?.toFixed();
    if (daysDiff > 3) {
      t.Snoozed = null;
    }
  }

	var initialUserName = t.Assignee != null ? t.Assignee.initial_assignment : '' // eslint-disable-line
	var assignee = t.Assignee != null ? t.Assignee : t.Username // eslint-disable-line
  let username = t.Username != null ? t.Username.toLowerCase() : t.Username;
  if (t.ID != null) {
    for (var x = 0; x < t.Columns.length; x++) {
      if (t.Columns[x].ColumnType == 'USER' && t.Columns[x].CoreField) {
        username = t.Columns[x].Response;
      }
    }
  }




  const tasq = {
    id: t.PredictionID || t.ID || t.NodeID || t.wellName,
    scheduledJobID: t.ID != null ? t.ID : null,
    predictionType: t.PredictionType != null ? t.PredictionType : t.ID != null ? 'Scheduled Job' : 'Producing',
    engineerType: t.PredictionType != null ? ucfirst(t.PredictionType === 'Off-Target RT' ? 'Real-Time' : t.PredictionType || '') : t.ID != null ? 'Scheduled Job' : 'Producing',
    wellName,
    padName: t.PadName,
    area: t.Area,
    date: t?.StateChangeDate,
    stateChangeStart: t.StateChangeDate,
    route: t.Route,
    stateChangeEnd: new Date(),
    // predictionsList: t.Predictions,
    time: t.time,
    lastResponseTime: t.LastResponseTime ? t.LastResponseTime : [],
    // lastResponseData: t.LastResponseData ? t.LastResponseData : [],
    stateChangeDate: t.StateChangeDate,
    scheduledJobStartDate: t['Start Date'] != null ? new Date(t['Start Date']) : null,
    scheduledJobDueDate: t['Due Date'] != null ? new Date(t['Due Date']) : null,
    responseData: t.ResponseData,
    viewed: t.Viewed,
    snoozed: t.Snoozed,
    activeTodolistForUser: t.activeTodolistForUser || false,
    activeTodolistCount: t.activeTodolistCount || 0,
    isAlarmActive: t.AlarmPayload && t.AlarmPayload.alarm,
    isShutdownAlarmActive: t.AlarmPayload && t.AlarmPayload.shutdown,
    alarmType: t.AlarmPayload && t.AlarmPayload.alarm_type ? t.AlarmPayload.alarm_type : null,
    alarmTime: t.AlarmPayload && t.AlarmPayload.time ? t.AlarmPayload.time : null,
    waitUntilReason: t.Snoozed ? t.Snoozed.body : '',
    isSnoozedForToday: getSnoozedForTodayStatus(t),
    waitingOnEndDate: t.Snoozed ? t.Snoozed.end_date : '',
    waitingOnStartDate: t.Snoozed ? t.Snoozed.start_date : '',
    rtCauseSignal: (t?.Payload != null && t?.Payload.Signal != null) ? t?.Payload.Signal : null,
    highLP: (t?.Payload != null && t?.Payload.HighLP != null && t?.Payload.HighLP) ? 'Line Pressure' : null,
    isViewed: (t.Viewed || []).includes(userEmail),
    assignee,
    isLocked: t.locked && (t.locked || {}).Status,
    isRepetitive: t.repetitive,
    isSnoozed: t.snoozed,
    isRejected: t.rejected,
    noComms: t.PredictionType === TASQ_NO_COMMS_TYPE,
    isWorkflowGen: t.WorkflowDetailsID || false,
    workflowTasqId: t.WorkflowTaskID,
    workflowDetailsID: null,
    setpointRecord: {},
    isGoodCatch: t.GoodCatch,
    username: username, // eslint-disable-line
    reassignedUserName: t.Assignee != null && t.Assignee.reassignment != null ? t.Assignee.reassignment.new_assignee || '' : '', // eslint-disable-line
	initialUserName: initialUserName, // eslint-disable-line
    isManuallyAdded: t.ManuallyAssigned,
    offTargetStartDate: t.OffTargetStartDate,
    isWatched: t?.Watchlist?.includes(userEmail),
    assignmentTime: t?.AssignmentTime,
    predictionTypes: t.PredictionTypes && t.PredictionTypes.length ?  [...new Set(t.PredictionTypes)] : [t.PredictionType],
    completed: t.Closed && t.Closed == 'True',
    completedForToday: t.CompletedForToday != null ? (new Date(`${t.CompletedForToday}Z`).setHours(0, 0, 0, 0) == (new Date()).setHours(0, 0, 0, 0)) : false,
    defermentValue: t?.DefermentValue,
    defermentType: (t?.Payload != null && t?.Payload.DefermentType != null) ? t?.Payload.DefermentType : 'gas_rate',
    // @ts-ignore
    defermentPercentage: (t?.Payload != null && t?.Payload.DefermentPerc != null) ? +parseFloat(t?.Payload.DefermentPerc * 100).toFixed(0) : null,
    defermentUnit: (t?.Payload != null && t?.Payload.Units != null) ? t?.Payload.Units : 'bpd',
    deferringDays: (t?.Payload != null && t?.Payload.DeferringDays != null) ? t?.Payload.DeferringDays : null,
    outcome: (t?.Payload != null && t?.Payload.Outcome != null) ? t?.Payload.Outcome : null,
    // comments: t.comments,
    wells: [],
    overriddenName: t.ManuallyAssigned ? t.PredictionType : getOverriddenTasqName(t),
    // stepOneCompleted: false,
    // stepTwoCompleted: false,
    // validationResponse: t.ValidationResponse || {},
    // formsResponse: typeof t.FormsResponse === 'object' && t.FormsResponse && t.FormsResponse.length ? t.FormsResponse :  [],
    team: t.Team || t.Teama,
    respondedTwoDaysAgo: false,
    padDeferment: t.PadDeferment ?  parseFloat(parseFloat(t.PadDeferment).toFixed(0)) : null,
    respondedThreeDaysAgo: false,
    respondedFourDaysAgo: false,
    level: t.Level != null ? t.Level : t.level != null ? t.level : 'WELL',
    stepLabel: '',
    scheduledJobStatus: t.Status != null ? t.Status.Status : null,
    scheduledJobColumns: t.Columns != null ? t.Columns : null,
    dateAgoText: '',
    wellTestData: null,
    lastActionTime: 0,
    views: t.ViewsCount ? t.ViewsCount : {},
    actions: t.ActionsCount ? t.ActionsCount : {},
    setpointAccepted: t.Accepted,
    tasqType: t.TasqType,
    creationComment: t.CreationComment,
    createdBy: t.CreatedBy
  };

  if (t.WorkflowDetailsID != null) {
    tasq.workflowDetailsID = t.WorkflowDetailsID;
  }
  if (t.Payload != null && t.PredictionType != null  &&  t.Payload && (t.PredictionType.toLowerCase() == 'anomaly' || t.PredictionType.toLowerCase() == 'state change')) {
    tasq.stateChangeStart = t.Payload.Record.StateChangeDate;
    tasq.stateChangeEnd = new Date(); // t.Payload.Record.StateChangeDateEnd
    tasq.stateChangeDate = t.Payload.Record.StateChangeDate;
    tasq.date = t.Payload.Record.StateChangeDate;
  } else if (t.PredictionType != null  &&  t.Payload &&  t.PredictionType.toLowerCase() == 'off-target' && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(' ', 'T');
    tasq.defermentValue = t.Payload.MCF;
  } else if (t.PredictionType != null && t.PredictionType.toLowerCase() == 'setpoint'  &&  t.Payload && t.Payload.OffTargetStartDate) {
    tasq.offTargetStartDate = t.Payload.OffTargetStartDate.replaceAll(' ', 'T');
    tasq.defermentValue = t.Payload.MCF;
  } else if (t.PredictionType != null && t.PredictionType.toLowerCase() == 'off-target rt' &&  t.Payload && t.Payload['Event Start']) {
    tasq.offTargetStartDate = t.Payload['Event Start'].replaceAll(' ', 'T');
    const updatedDate = DateTime.fromISO(t.Payload['Event Start'], { zone: 'America/Denver' });
    // @ts-ignore
    tasq.rtStartDate = updatedDate.toString();
    tasq.defermentValue = t.Payload.MCF;
  }


  if(t.Payload && t.Payload.Record){
    tasq.setpointRecord = t.Payload.Record
  }

  if (t.Wells != null) {
	  tasq.wells = t.Wells.map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );
  } else if (t.wells != null) {
    tasq.wells = t.wells.map(
      (t: TasqJobResponse) => mapDefaultTasq(t),
    );
  }

  // @ts-ignore
  tasq.dateAgoText = getDateAgoText(tasq).text;
  // @ts-ignore
  tasq.dateAgoValue = getDateAgoText(tasq).value;

  // @ts-ignore

  if (t.TaskID != null && t.ClusterID != null && t.GanttChartID != null) {
	  // @ts-ignore
    tasq.wellTestData = {
      // @ts-ignore
      taskID: t.TaskID,
      // @ts-ignore
      clusterID: t.ClusterID,
      // @ts-ignore
      ganttChartID: t.GanttChartID,
    };
  }




  // @ts-ignore
  return tasq;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultTasq,
};
